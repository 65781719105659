import React, { useEffect, useState } from "react";

import { Grid, Paper, Tabs, Tab } from "@material-ui/core/";
import ExpenseQueryForm from "components/partials/user/ExpenseQueryForm";
import { useSelector, useDispatch } from "react-redux";
import { clearAccounts, getAccounts, getExpenseTypes, getMoneyMovements } from "redux/actions/accountActions";
import SalesCard from "components/partials/user/SalesCard";
import ExpenseListTable from "components/partials/user/ExpenseListTable";
import IncomeListTable from "components/partials/user/IncomeListTable";

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};
const FinancePage = ({ history }) => {
    const accountState = useSelector((state) => state.account);
    const [tab, setTab] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const dispatch = useDispatch();
    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false)
            dispatch(getAccounts())
            dispatch(getExpenseTypes())
            dispatch(getMoneyMovements({}))
        }
        
        return () => {
            dispatch(clearAccounts());
        }
    }, [dispatch]);
    return (
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
            {/*Total amount*/}
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
                    <Grid item xs={12} md={6}>
                        <SalesCard
                            title={"Total de Gastos"}
                            color={"error"}
                            value={accountState.totalExpense}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SalesCard
                            title={"Total de Ingresos"}
                            color={"success"}
                            value={accountState.totalIncome}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
                    <Grid item xs={12} md={12}>
                        <Paper>
                            <ExpenseQueryForm />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper>
                            <Tabs
                                value={tab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(e, v) => setTab(v)}
                                aria-label="Usuarios"
                            >
                                <Tab label="Ingresos" />
                                <Tab label="Egresos" />
                            </Tabs>

                            <TabPanel value={tab} index={0}>
                                <IncomeListTable history={history} income={accountState.income} />
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <ExpenseListTable history={history} expense={accountState.expense}  />
                            </TabPanel>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FinancePage;
