import React from "react";

import MaterialTable from "material-table";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import es from "date-fns/locale/es";

const tableStyle = makeStyles((theme) => ({
  paper: {
    whiteSpace: 'nowrap',
    width: "100%"
  }
}));


const GenericRestfulTable = (props) => {

  const classes = tableStyle({});

  return (
    <MaterialTable
      {...props}
      title={props.title}
      columns={props.dataColumns ? props.dataColumns : []}
      data={props.data ? props.data : []}
      editable={{
        onRowUpdate: props.handleRowUpdate? (newData, oldData) =>
          new Promise((resolve) => {
            props.handleRowUpdate(newData, oldData, resolve);
          }):null,
        onRowAdd: props.handleRowAdd? (newData) =>
          new Promise((resolve) => {
            props.handleRowAdd(newData, resolve)
          }):null,
        onRowDelete: props.handleRowDelete? (oldData) =>
          new Promise((resolve) => {
            props.handleRowDelete(oldData, resolve)
          }):null,
      }}
      actions={ props.rowAction ? props.rowAction : null }
      options={{ actionsColumnIndex: -1, exportButton: true }}
      components={{
        Container: props => <Paper {...props} className={classes.paper} />,
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: 'entradas',
          firstTooltip: 'Primera página',
          lastTooltip: 'Última página',
          nextTooltip: 'Página siguiente',
          previousTooltip: 'Página previa'
        },
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar...',
          exportTitle: 'Exportar',
          exportCSVName: 'Exportar como CSV',
          exportPDFName: 'Exportar como PDF',
          exportAriaLabel: 'Exportar',
          exportButton: 'Exportar'
        },
        header: {
          actions: 'Acciones'
        },
        body: {
          dateTimePickerLocalization: es,
          emptyDataSourceMessage: 'No hay datos para mostrar :(',
          filterRow: {
            filterTooltip: 'Filtrar'
          },
          addTooltip: 'Nuevo',
          deleteTooltip: 'Borrar',
          editTooltip: 'Editar',
          editRow: {
            saveTooltip: 'Guardar',
            cancelTooltip: 'Descartar',
            deleteText: `¿Seguro en borrar esta entrada?`
          }
        },
      }}
    />
  );
};

export default GenericRestfulTable;
