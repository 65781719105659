import {
    SET_ORDER,
    SET_ORDERS,
    SET_ORDER_LOOKUP,
    SET_ORDER_LOOKUP_CLIENT
} from "redux/actions/types";

const initialState = {
    orders: null,
    order: null,
    lookUpUsers: null,
    lookUpClients: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ORDERS:
            return {
                ...state,
                orders: action.payload,
            };
        case SET_ORDER:
            return {
                ...state,
                order: action.payload,
            };
        case SET_ORDER_LOOKUP:
            return {
                ...state,
                lookUpUsers: action.payload
            }

        case SET_ORDER_LOOKUP_CLIENT:
            return {
                ...state,
                lookUpClients: action.payload
            }
        default:
            return state;
    }
}