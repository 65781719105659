import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getOrders, updateOrder, deleteOrder, clearOrders, createOrder } from "redux/actions/workOrderActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";

import RestfulTable from "./GenericRestfulTable";

const WorkOrderListTable = ({ history, serviceType, workOrderStatus, paymentMethods, clientId = null }) => {

    const dispatch = useDispatch();

    const workOrderState = useSelector((state) => state.order);
    const workOrders = workOrderState.orders ?? [];
    const lookupClients = workOrderState.lookUpClients ?? [];
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const rowAction = [
        {
            icon: 'visibility',
            tooltip: 'Ver orden de trabajo',
            onClick: (event, rowData) => history.push(`/orders/${rowData._id}`)
        }
    ];

    const columns = [
        {
            title: '_id',
            field: '_id',
            hidden: true
        },
        {
            title: "Cliente",
            field: "client",
            lookup: lookupClients,
            initialEditValue: clientId
        },
        {
            title: 'Servicio',
            field: 'service'
        },
        {
            title: 'Tipo',
            field: 'serviceType',
            lookup: serviceType,
            initialEditValue: "CONSULTORIA"
        },
        {
            title: 'Estado',
            field: 'status',
            lookup: workOrderStatus,
            initialEditValue: "EN_ESPERA"
        },
        {
            title: 'Número de orden',
            field: 'orderNumber',
        },
        {
            title: 'Duración',
            field: 'days',
            type: 'numeric'
        },
        {
            title: 'Inicio',
            field: 'start',
            type: 'date'
        },
        {
            title: 'Fin',
            field: 'end',
            type: 'date'
        },
        {
            title: 'Forma de pago',
            field: 'paymentMethod',
            lookup: paymentMethods,
            initialEditValue: 'CONTADO'
        }, {
            title: 'Costo',
            field: 'cost',
            type: 'currency'
        }
    ];

    const handleRowUpdate = (newData, oldData, resolve) => {
        if (newData.name === "") {
            dispatch(setAlert("El nombre es requerido", 'error'));
            dispatch({ type: CLEAR_ERRORS });
            resolve();
        }
        else {
            dispatch(updateOrder(resolve, oldData._id, newData));
        }
    }

    const handleRowAdd = (newData, resolve) => {
        dispatch(createOrder(resolve, newData.serviceType,
            newData.service,
            newData.client,
            newData.status,
            newData.orderNumber,
            newData.description,
            newData.days,
            newData.start,
            newData.end,
            newData.paymentMethod,
            newData.cost));
    }

    const handleRowDelete = (oldData, resolve) => {
        dispatch(deleteOrder(resolve, oldData._id));
    }

    const fetchData = async () => {
        setIsLoading(true);
        await dispatch(getOrders());
        setIsLoading(false);
    }

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            fetchData();
        }
        return () => {
            dispatch(clearOrders());
        }
    }, [dispatch, isFirstLoad]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <RestfulTable
            isLoading={isLoading}
            onDataFetch={fetchData}
            title={"Lista de Órdenes de trabajo"}
            data={workOrders ? workOrders : []}
            dataColumns={columns}
            handleRowUpdate={handleRowUpdate}
            handleRowDelete={handleRowDelete}
            handleRowAdd={handleRowAdd}
            rowAction={rowAction} />
    );
}

export default WorkOrderListTable;