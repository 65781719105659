import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setAlert } from "redux/actions/alertAction";
import { getLeads, updateLead, deleteLead, createLead } from "redux/actions/leadActions";
import { CLEAR_ERRORS } from "redux/actions/types";

import Grid from "@material-ui/core/Grid";
import ClientTable from "components/partials/user/ClientListTable";

import RestfulTable from './GenericRestfulTable';
import LeadCard from "./LeadCard";

const LeadRESTTable = ({role, id, viewOnly,serviceType, history }) => {

  const dispatch = useDispatch();

  const leadsState = useSelector((state) => state.lead);
  const leads = leadsState.leads;
  const lookupUsers = leadsState.lookupUsers;
  const lookupClients = leadsState.lookUpClients;

  const status = leadsState.leadstatus;
  const [isLoading, setIsLoading] = useState(false);

  var columns = [
    { title: "_id", field: "_id", hidden: true },
    { title: "Nombre", field: "name" },
    {
      title: "Status",
      field: "status",
      lookup: status,
      initialEditValue: "OPORTUNIDAD"
    },
    {
      title: "Usuario",
      field: "owner",
      lookup: lookupUsers,
      initialEditValue: id
    },
    {
      title: "Prospecto",
      field: "client",
      lookup: lookupClients,
      initialEditValue:null
    },
    { title: "Ubicación", field: "location" },
    {
      title: 'Tipo de servicio',
      field: 'serviceType',
      lookup: serviceType,
      initialEditValue: "CONSULTORIA"
  },
    { title: "Comentarios", field: "comments" }
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {
    if (viewOnly) {
      resolve();
      return;
    }

    if (newData.name === "") {
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({ type: CLEAR_ERRORS });
      resolve();
    }

    else {
      dispatch(updateLead(resolve, oldData._id, newData));
    }
  }

  const handleRowAdd = (newData, resolve) => {
    if (viewOnly) {
      resolve();
      return;
    }

    if (newData.name === "") {
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({ type: CLEAR_ERRORS });
      resolve();
    }
    else {
      dispatch(createLead(resolve, newData));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    if (viewOnly) {
      resolve();
      return;
    }

    dispatch(deleteLead(resolve, oldData._id));
  }

  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(getLeads(role, id));
    setIsLoading(false); 
  }

  useEffect(() => {
    leads == null &&
      dispatch(getLeads(role, id));
  }, [dispatch, leads, role, id]);
  
  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          {
            status && Object.keys(status).map((state, i) => {
              return (
                <Grid item xs={12} md={3} key={state}>
                  <LeadCard
                    title={status[state]}
                    color={"success"}
                    lookUpUsers={lookupUsers}
                    data={leads && leads.filter((lead) => {
                      return lead.status === state;
                    })}
                  />
                </Grid>
              );
            })
          }
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <RestfulTable
          isLoading={isLoading}
          onDataFetch={fetchData}
          title={"Oportunidades"}
          data={leads}
          dataColumns={columns}
          handleRowAdd={viewOnly ? null : handleRowAdd}
          handleRowUpdate={viewOnly ? null : handleRowUpdate}
          handleRowDelete={viewOnly ? null : handleRowDelete}
        />
      </Grid>
      <Grid item xs={12}>
        <ClientTable history={history} clientDefaultType={ "PROSPECTO"} />
      </Grid>
    </Grid>
  );
};

export default LeadRESTTable;
