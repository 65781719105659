import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getUsers, updateUser, deleteUser } from "redux/actions/userActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";
import RestfulTable from "./GenericRestfulTable";
import { clearUsers } from "../../../redux/actions/userActions";

const UserListTable = ({ history }) => {

  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const users = userState.users;
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  const rowAction = [
  ];

  const columns = [
    {
      title: '_id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Nombre',
      field: 'name'
    },
    {
      title: 'Email',
      field: 'email'
    },
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    if(newData.email === ""){
      dispatch(setAlert("El email es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(updateUser(resolve, oldData._id, newData.name, newData.email));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    dispatch(deleteUser(resolve, oldData._id));
  }

  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(getUsers());
    setIsLoading(false); 
  }

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      fetchData();
    }
    return () => {
      dispatch(clearUsers());
    }
  }, [dispatch, isFirstLoad]); // eslint-disable-line react-hooks/exhaustive-deps

  

  return (
    <RestfulTable
      isLoading={isLoading}
      onDataFetch={fetchData}
      title={"Lista de Usuarios"}
      data={users?users:[]}
      dataColumns={columns}
      handleRowUpdate={handleRowUpdate}
      handleRowDelete={handleRowDelete}
      rowAction={rowAction} />
  );
}

export default UserListTable;