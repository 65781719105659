import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@material-ui/core/";
import LeadTable from "components/partials/user/LeadRESTTable";
import { clearLeads } from "redux/actions/leadActions";

const LeadPage = ({history}) => {
  const authState = useSelector((state) => state.auth);
  const user = authState.user.data;
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearLeads());
    }
  }, [dispatch]);
  const serviceType = {
    CURSO: 'CURSO',
    CONSULTORIA: 'CONSULTORIA',
    AUDITORIA: 'AUDITORIA',
  }
  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      <LeadTable role={user.role} id={user._id} viewOnly={false} serviceType={serviceType} history={history} />
    </Grid>
  );
};

export default LeadPage;
