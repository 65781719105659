export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const LOGIN_SUCCESS = "FORGET_SUCCESS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const SET_DRAWERLIST = "SET_DRAWERLIST";
export const SET_SALES = "SET_SALES";
export const RESET_STATE = "RESET_STATE";
export const REMEMBER_ME = "REMEMBER_ME";
export const SET_INVOICES = "SET_INVOICES";
export const SET_USERS = "SET_USERS";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_INVOICE = "SET_INVOICE";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const SET_CLIENTS = "SET_CLIENTS";
export const SET_VERIFIER_METRICS = "SET_VERIFIER_METRICS";
export const SET_QUEUE = "SET_QUEUE";
export const SET_LEAD = "SET_LEAD";
export const SET_LEADSTATUS = "SET_LEADSTATUS";
export const SET_LOOKUP = "SET_LOOKUP";
export const SET_IS_OTHER_USER = "SET_IS_OTHER_USER";
export const SET_TIME = "SET_TIME";
export const USER_FORM_LOADING = "USER_FORM_LOADING";
export const INVOICE_FORM_LOADING = "INVOICE_FORM_LOADING";
export const  SET_LOOKUP_CLIENT = "SET_LOOKUP_CLIENT";
export const SET_CLIENT = "SET_CLIENT";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_EXPENSE_TYPES = "SET_EXPENSE_TYPES";
export const SET_EXPENSE = "SET_EXPENSE";
export const SET_INCOME = "SET_INCOME";
export const SET_TOTAL_EXPENSE = "SET_TOTAL_EXPENSE";
export const SET_TOTAL_INCOME = "SET_TOTAL_INCOME";
export const SET_ORDERS= "SET_ORDERS";
export const SET_ORDER= "SET_ORDER";
export const SET_ORDER_LOOKUP_CLIENT = "SET_ORDER_LOOKUP_CLIENT";
export const SET_ORDER_LOOKUP = "SET_ORDER_LOOKUP";