import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import { updateClient } from "redux/actions/clientActions";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  fileButton: {
    fontSize: "0.8rem",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(0, 1, 0),
  },
  grid: {
    marginTop: theme.spacing(1),
  },
  dividerColor: {
    backgroundColor: theme.palette.primary.main,
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
}));

const UpdateClientForm = ({client, id}) => {
  const classes = useStyles();

  const authState = useSelector((state) => state.auth);
  const formLoading = authState?.formLoading;
  const [contact, setContact] = useState(client.contact);
  const [newClient, setNewClient] = useState({
    name: client.name,
    clientType: client.clientType,
    description: client.description,
    comments: client.comments
  });

  const {  name,
    clientType,
    description,
    comments } = newClient;
  const onChange = (e) =>
    setNewClient({ ...newClient, [e.target.name]: e.target.value });

  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    await  new Promise((resolve) => {
        dispatch(updateClient(resolve,id,  newClient.clientType, newClient.name, newClient.shortName, newClient.description, newClient.comments, contact, client.activities ?? []));
      });
  };

  
  const removeItem = (i) => {
    const newContact = [...contact];

    newContact.splice(i, 1);
    setContact(newContact);
  };

  const addItem = () => {
    setContact([...contact, {contactType:"Email", contactValue:"", contactName:""}]);
  };

  const changeContactType = (e,i) => {
    const newContact = [...contact];
    newContact[i].contactType = e.target.value;
    setContact(newContact);
  };
  const changeContactValue = (e,i) => {
    const newContact = [...contact];
    newContact[i].contactValue = e.target.value;
    setContact(newContact);
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Cliente
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nombre"
            name="name"
            helperText="Nombre del Usuario"
            autoComplete="off"
            autoFocus
            value={name}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="description"
            label="Descripción"
            name="description"
            helperText="Descripción del cliente"
            autoComplete="off"
            value={description}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="comments"
            label="Comentarios"
            name="comments"
            helperText="Comentarios del cliente"
            autoComplete="off"
            value={comments}
            onChange={onChange}
          />
            <FormControl
              required
              variant="outlined"
              margin="normal"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel>Tipo de Cliente</InputLabel>
              <Select
                id="clientType"
                value={clientType}
                name="clientType"
                onChange={onChange}
                label="Tipo de Cliente"
              >
                <MenuItem value={"ALIANZA"}>Alianza Estratégica</MenuItem>
                <MenuItem value={"ASOCIADO"}>Asociado</MenuItem>
                <MenuItem value={"CLIENTE"}>Cliente</MenuItem>
                <MenuItem value={"COMPETIDOR"}>Competidor</MenuItem>
                <MenuItem value={"ENTIDAD"}>Entidad Reguladora</MenuItem>
                <MenuItem value={"PROSPECTO"}>Prospecto</MenuItem>
                <MenuItem value={"PROVEEDOR"}>Proveedor</MenuItem>
              </Select>
              <FormHelperText>Tipo de Cliente</FormHelperText>
            </FormControl>
          {!formLoading &&
            <Button
              type="submit"
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.submit}
              value="upload"
            >
              Guardar
          </Button>
          }
          {formLoading && <CircularProgress />}
        </form>
      </CardContent>
    </Card>
  );
};

export default UpdateClientForm;
