import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, useLocation } from "react-router-dom";

import { Role, hasRole } from "utils/authUtils";
import PrivateRoute from "components/private-route/PrivateRoute";
import { setUserLoading, logoutUser } from "redux/actions/authActions";

import AdminPage from "components/pages/AdminPage";
import UserPage from "components/pages/UserPage";
import LeadPage from "components/pages/LeadPage";
import ClientPage from "components/pages/ClientPage";

import DrawerList from "components/partials/DrawerList";

import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Typography,
  MenuItem,
  Menu,
  Drawer,
} from "@material-ui/core";

import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";

import logo from "assets/logo.png";
import AccountPage from "components/pages/AccountPage";
import FinancePage from "components/pages/FinancePage";
import OrderPage from "components/pages/WorkOrderPage";


const drawerWidth = 240;

//User Layout Styles
const userLayoutStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.primary.main,
    color: "#fff",
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    width: "75px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: {
    padding: theme.spacing(2),
  },
}));

const UserLayout = ({ history, window, width }) => {
  //Get auth state
  const authState = useSelector((state) => state.auth);

  const classes = userLayoutStyles({});
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      history.push("/login");
    }
    if (authState.forceReset) {
      history.push("/reset");
    }
    if (authState.user == null || authState.user === {}) {
      dispatch(setUserLoading());
    }
  }, [authState, history, dispatch]);

  const [menu, setMenu] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setMenu(event.currentTarget);
  };
  const handleProfileMenuOMenuClose = () => {
    setMenu(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const menuId = "account-menu";
  const isMenuOpen = Boolean(menu);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt="Logo" className={classes.logo} />
          <Typography className={classes.title} variant="caption" noWrap>
            SIAA
          </Typography>
          <div className={classes.grow} />
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={menu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        id={menuId}
        keepMounted
        open={isMenuOpen}
        onClose={handleProfileMenuOMenuClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(logoutUser());
          }}
        >
          Salir
        </MenuItem>
      </Menu>
      <Hidden smUp implementation="js">
        <Drawer
          container={container}
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerList history={history} />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="js">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <DrawerList history={history} />
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <Toolbar />
        <div className={classes.paper}>
          <Switch location={location}>
            {hasRole(authState.user, [Role.ADMIN]) && (
              <PrivateRoute path="/dashboard" component={AdminPage} />
            )}
            {hasRole(authState.user, [Role.USER]) && (
              <PrivateRoute path="/dashboard" component={UserPage} />
            )}
            {hasRole(authState.user, [
              Role.ADMIN,
              Role.USER,
            ]) && <PrivateRoute path="/leads" component={LeadPage} />}
            {hasRole(authState.user, [
              Role.ADMIN,
              Role.USER,
            ]) && <PrivateRoute path="/clients/:id" component={ClientPage} />}
            {hasRole(authState.user, [
              Role.ADMIN,
              Role.USER,
            ]) && <PrivateRoute path="/clients" component={ClientPage} />}
            {hasRole(authState.user, [
              Role.ADMIN,
              Role.USER,
            ]) && <PrivateRoute path="/accounts" component={AccountPage} />}

            {hasRole(authState.user, [
              Role.ADMIN,
              Role.USER,
            ]) && <PrivateRoute path="/finance" component={FinancePage} />}
            {hasRole(authState.user, [
              Role.ADMIN,
              Role.USER,
            ]) && <PrivateRoute path="/orders/:id" component={OrderPage} />}
            {hasRole(authState.user, [
              Role.ADMIN,
              Role.USER,
            ]) && <PrivateRoute path="/orders" component={OrderPage} />}
          </Switch>
        </div>
      </main>
    </div>
  );
};

export default UserLayout;
