import React from "react";
import { Route } from "react-router-dom";

const DecisionRoute = ({ trueComponent, falseComponent, exp, ...rest }) => {
  return (
    <Route
      {...rest}

      render={
        exp
          ? trueComponent
          : falseComponent
      }
    />
  )
}

export default DecisionRoute;