import React, { useState } from "react";

import { Grid, Tabs, Tab, Paper } from "@material-ui/core/";
import RegisterUserForm from "components/partials/user/RegisterUserForm";
import UserTable from "components/partials/user/UserListTable";
import ClientTable from "components/partials/user/ClientListTable";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};
const AdminPage = ({ history }) => {
  const [tab, setTab] = useState(0);


  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      {/* New User Form + table*/}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          {/*Distributor Register*/}
          <Grid item xs={12} md={12}>
            <RegisterUserForm isAdmin={true} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper>
              <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, v) => setTab(v)}
                aria-label="Usuarios"
              >
                <Tab label="Usuarios" />
                <Tab label="Clientes" />
              </Tabs>

              <TabPanel value={tab} index={0}>
                <UserTable history={history} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <ClientTable history={history} />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminPage;
