import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import es from "date-fns/locale/es";

import format from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Grid,
  Divider,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { updateOrder } from "redux/actions/workOrderActions";
import WorkOrderActivityTable from "./WorkOrderActivityTable";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  fileButton: {
    fontSize: "0.8rem",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(0, 1, 0),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
}));

const WorkOrderForm = ({ history, serviceType, id, workOrderStatus, paymentMethods }) => {
  const classes = useStyles();

  const orderState = useSelector((state) => state.order);
  const order = orderState.order;
  const lookUpClients = orderState.lookUpClients;

  const [workOrder, setWorkOrder] = useState({
    serviceType: order.serviceType,
    service: order.service,
    client: order.client,
    status: order.status,
    orderNumber: order.orderNumber,
    description: order.description,
    days: order.days,
    start: new Date(order.start),
    end: new Date(order.end),
    paymentMethod: order.paymentMethod,
    cost: order.cost,
  });

  const [phases, setPhases] = useState(order.phases);

  const [pickDate, setPickDate] = useState('None');
  const setDate = (value) => {
    if (pickDate === 'Start') {
      setWorkOrder({ ...workOrder, start: value });
    }
    else if (pickDate === 'End') {
      setWorkOrder({ ...workOrder, end: value });

    }
  };

  const [formLoading, setFormLoading] = useState(false);


  const onChange = (e) =>
    setWorkOrder({ ...workOrder, [e.target.name]: e.target.value });

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    setFormLoading(true);
    e.preventDefault();
    const newOrder = { ...workOrder };
    newOrder.phases = phases;
    dispatch(updateOrder(() => setFormLoading(false), id, newOrder));
  };

  const changeDate = (date) => {
    setDate(date);
    setPickDate('None');
  };

  const removePhase = (i) => {
    const newPhases = [...phases];

    newPhases.splice(i, 1);
    setPhases(newPhases);
  };
  const addPhase = () => {
    setPhases([...phases, { title: "", activities: [] }]);
  };

  const changePhaseTitle = (title, i) => {
    const newPhases = [...phases];
    newPhases[i].title = title;
    setPhases(newPhases);
  };

  const removePhaseActivity = (phase, activity) => {
    const newPhases = [...phases];
    newPhases[phase].activities.splice(activity, 1);
    setPhases(newPhases);
  };

  const changePhaseActivity = (phase, activity, newData) => {
    const newPhases = [...phases];
    newPhases[phase].activities[activity] = newData;
    setPhases(newPhases);
  };

  const addPhaseActivity = (phase, newData) => {
    const newPhases = [...phases];
    newPhases[phase].activities.push({ ...newData });
    setPhases(newPhases);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h4" component="h2">
          Orden de trabajo {workOrder.orderNumber} -  {workOrder.service}
        </Typography>
        <Typography gutterBottom variant="h5" component="h3" onClick={() => history.push(`/clients/${workOrder.client}`)}>
          {lookUpClients[workOrder.client]}
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="description"
            label="Descripción"
            name="description"
            helperText="Descripción"
            autoComplete="off"
            autoFocus
            value={workOrder.description}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="days"
            label="Duración"
            name="days"
            helperText="Duración"
            autoComplete="off"
            autoFocus
            value={workOrder.days}
            onChange={onChange}
          />
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Cantidad</InputLabel>
            <OutlinedInput
              variant="outlined"
              margin="normal"
              fullWidth
              id="cost"
              label="Costo"
              name="cost"
              helperText="Costo"
              autoComplete="off"
              autoFocus
              value={workOrder.cost}
              onChange={onChange}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Método de Pago</InputLabel>
            <Select
              id="paymentMethod"
              value={workOrder.paymentMethod}
              name="paymentMethod"
              onChange={onChange}
              label="Método de Pago"
            >
              {Object.keys(paymentMethods).map(value => <MenuItem value={value} key={value} >{value}</MenuItem>)}
            </Select>
            <FormHelperText>Método de Pago</FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Tipo de Servicio</InputLabel>
            <Select
              id="serviceType"
              value={workOrder.serviceType}
              name="serviceType"
              onChange={onChange}
              label="Tipo de Servicio"
            >
              {Object.keys(serviceType).map(value => <MenuItem value={value} key={value} >{value}</MenuItem>)}
            </Select>
            <FormHelperText>Tipo de Servicio</FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            margin="normal"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Estado</InputLabel>
            <Select
              id="workOrderStatus"
              value={workOrder.status}
              name="status"
              onChange={onChange}
              label="Estado"
            >
              {Object.keys(workOrderStatus).map(value => <MenuItem value={value} key={value} >{value}</MenuItem>)}
            </Select>
            <FormHelperText>Estado</FormHelperText>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            label="Fecha de inicio"
            fullWidth
            value={format(workOrder.start, "eee, dd MMM yyyy", {
              locale: es,
            })}
            onChange={(e) => e.preventDefault()}
            onClick={() => setPickDate(pickDate === 'Start' ? 'None' : 'Start')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            label="Fecha de fin"
            fullWidth
            value={format(workOrder.end, "eee, dd MMM yyyy", {
              locale: es,
            })}
            onChange={(e) => e.preventDefault()}
            onClick={() => setPickDate(pickDate === 'End' ? 'None' : 'End')}
          />
          <div
            style={{
              position: "absolute",
              visibility: pickDate === 'None' ? "hidden" : "visible",
              zIndex: "9999999999"
            }}
          >
            <Calendar onChange={changeDate} value={pickDate === 'Start' ? workOrder.start : workOrder.end} locale={"es"} />
          </div>
          {phases.map((value, i) => {
            return (
              <Grid
                key={`phase${i}`}
                className={classes.grid}
                container
                spacing={2}
                alignItems="center"
                justify="center"
              >

                <Grid item xs={5} md={5}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Título"
                    name={`phaseTitle${i}`}
                    type="text"
                    id={`phaseTitle${i}`}
                    value={phases[i].title}
                    onChange={(e) => changePhaseTitle(e.target.value, i)}
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <Button
                    type="button"
                    color="primary"
                    margin="normal"
                    variant="outlined"
                    onClick={() => removePhase(i)}
                  >
                    -
                  </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                  <WorkOrderActivityTable phase={i} addPhaseActivity={addPhaseActivity} changePhaseActivity={changePhaseActivity} removePhaseActivity={removePhaseActivity} activities={phases[i].activities} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider
                    classes={{ root: classes.dividerColor }}
                    variant="middle"
                    light
                  />
                </Grid>
              </Grid>
            );
          })}
          <Grid className={classes.grid} container spacing={2}>
            <Grid item xs={12}>
              <Button
                type="button"
                color="secondary"
                margin="normal"
                variant="contained"
                fullWidth={true}
                onClick={addPhase}
              >
                Añadir Fase +
              </Button>
            </Grid>
          </Grid>
          {!formLoading &&
            <Button
              type="submit"
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.submit}
              value="upload"
            >
              Guardar
            </Button>
          }
          {formLoading && <CircularProgress />}
        </form>
      </CardContent>
    </Card>
  );
};

export default WorkOrderForm;
