import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { registerMoneyMovement } from "redux/actions/accountActions.js";
import { setAlert } from "redux/actions/alertAction";
import es from "date-fns/locale/es";

import format from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  fileButton: {
    fontSize: "0.8rem",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(0, 1, 0),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
}));

const MoneyMovementForm = (props) => {
  const classes = useStyles();
  const accountState = useSelector((state) => state.account);
  const accounts = accountState.accounts;
  const expenseTypes = accountState.expenseTypes;

  const accountLookUp = accounts?.map(account => (<MenuItem value={account._id}>{account.name}</MenuItem>))
  const expenseTypeLookUp = expenseTypes?.map(expenseType => (<MenuItem value={expenseType.name}>{expenseType.name}</MenuItem>))

  const [clearForm, setClearform] = useState(false);

  const [newMoneyMovement, setNewMoneyMovement] = useState({
    concept: '',
    amount: 0,
    amountString: '',
    movementType: 'INGRESO',
    account: '',
  });

  const { concept, amount, amountString, movementType, account } = newMoneyMovement;

  const [date, setDate] = useState(new Date(Date.now()));
  const [open, setOpen] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const [expenseType, setExpenseType] = useState('');

  const onChange = (e) =>
    setNewMoneyMovement({ ...newMoneyMovement, [e.target.name]: e.target.value });

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    setFormLoading(true);
    e.preventDefault();
    const movement = newMoneyMovement;
    if (movementType === "EGRESO") {
      if (expenseType == null || expenseType === "") {
        dispatch(setAlert("Los egresos deben llevar cuenta contable", "error"));
        return;
      }
      movement.expenseType = expenseType;
    }
    movement.date = date;
    if (
      !concept || !amountString || !movementType || !date || !account
    ) {
      dispatch(setAlert("Favor de ingresar todos los campos", "error"));
    }
    else {
      setClearform(true);
      dispatch(registerMoneyMovement(() => { }, movement))
    }
    setFormLoading(false);
  };

  useEffect(() => {
    if (clearForm && !formLoading) {
      setNewMoneyMovement({
        concept: '',
        amount: 0,
        amountString: '',
        movementType: 'INGRESO',
        date: Date.now(),
        account: accounts[0]._id,
      });
      setClearform(false);
    }
  }, [formLoading, clearForm]);


  const changeDate = (date) => {
    setDate(date);
    setOpen(false);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Nuevo Movimiento
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <FormControl
            required
            variant="outlined"
            margin="normal"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Cantidad</InputLabel>
            <OutlinedInput
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="amount"
              label="Cantidad"
              name="amount"
              helperText="Cantidad"
              autoComplete="off"
              autoFocus
              value={amount}
              onChange={onChange}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
            />
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="amountString"
            label="Cantidad en letra"
            name="amountString"
            helperText="Cantidad en letra"
            autoComplete="off"
            autoFocus
            value={amountString}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="concept"
            label="Concepto"
            name="concept"
            helperText="Concepto"
            autoComplete="off"
            value={concept}
            onChange={onChange}
          />
          <FormControl
            required
            variant="outlined"
            margin="normal"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Tipo de Movimiento</InputLabel>
            <Select
              id="movementType"
              value={movementType}
              name="movementType"
              onChange={onChange}
              label="Tipo de Movimiento"
            >
              <MenuItem value={"INGRESO"}>INGRESO</MenuItem>
              <MenuItem value={"EGRESO"}>EGRESO</MenuItem>
            </Select>
            <FormHelperText>Tipo de Movimiento</FormHelperText>
          </FormControl>
          {movementType === "EGRESO" && <FormControl
            required
            variant="outlined"
            margin="normal"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Cuenta Contable</InputLabel>
            <Select
              id="expenseType"
              value={expenseType}
              name="expenseType"
              onChange={(e) => setExpenseType(e.target.value)}
              label="Cuenta Contable"
            >
              {expenseTypeLookUp}
            </Select>
            <FormHelperText>Cuenta Contable</FormHelperText>
          </FormControl>}
          <FormControl
            required
            variant="outlined"
            margin="normal"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Cuenta Bancaria</InputLabel>
            <Select
              id="account"
              value={account}
              name="account"
              onChange={onChange}
              label="Cuenta Bancaria"
            >
              {accountLookUp}
            </Select>
            <FormHelperText>Cuenta Bancaria</FormHelperText>
          </FormControl>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={format(date, "eee, dd MMM yyyy", {
              locale: es,
            })}
            onChange={(e) => e.preventDefault()}
            onClick={() => setOpen(!open)}
          />
          <div
            style={{
              position: "absolute",
              visibility: !open ? "hidden" : "visible",
              zIndex: "9999999999"
            }}
          >
            <Calendar onChange={changeDate} value={date} locale={"es"} />
          </div>
          {!formLoading &&
            <Button
              type="submit"
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.submit}
              value="upload"
            >
              Registrar
            </Button>
          }
          {formLoading && <CircularProgress />}
        </form>
      </CardContent>
    </Card>
  );
};

export default MoneyMovementForm;
