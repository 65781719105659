import {
    SET_CLIENTS,
    SET_CLIENT
  } from "redux/actions/types";
  
  const initialState = {
    clients: null,
    client: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case SET_CLIENTS:
        return {
          ...state,
          clients: action.payload,
        };
        case SET_CLIENT:
            return {
              ...state,
              client: action.payload,
            };
      default:
        return state;
    }
  }