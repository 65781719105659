import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { registerMoneyMovement, getMoneyMovements, deleteMoneyMovement, updateMoneyMovement } from "redux/actions/accountActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";

import RestfulTable from "./GenericRestfulTable";

const IncomeListTable = ({history, income}) => {

  const dispatch = useDispatch();

  const accountState = useSelector((state) => state.account);
  const accounts = accountState.accounts;
  const accountLookUp = accounts?.reduce((previous, account) => ({...previous,[account._id] :account.name}), {})

  const [isLoading, setIsLoading] = useState(false);

  const rowAction = [
  
  ];

  const columns = [
    {
      title: '_id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Concepto',
      field: 'concept'
    },
    {
      title: 'Cantidad',
      field: 'amount',
      type: 'currency'

    },
    {
      title: 'Cantidad en letra',
      field: 'amountString',
    },
    {
      title: 'Tipo de Movimiento',
      field: 'movementType',
      default: 'INGRESO',
      hidden: true
    },
    {
        title: 'Fecha',
        field: 'date',
        type: 'date'
      },
      {
        title: 'Cuenta Bancaria',
        field: 'account',
        lookup: accountLookUp,
      },
  ];

  const handleRowAdd = (newData, resolve) => {
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(registerMoneyMovement(resolve,newData));
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(getMoneyMovements());
    setIsLoading(false); 
  }

  const handleRowDelete = (oldData, resolve) => {
    dispatch(deleteMoneyMovement(resolve,oldData._id));
  }

  const handleRowUpdate = (newData, oldData, resolve) => {
    dispatch(updateMoneyMovement(resolve, oldData._id,  newData));
  }

  return (
    <RestfulTable
      isLoading={isLoading}
      onDataFetch={fetchData}
      title={"Lista de Ingresos"}
      data={income?income:[]}
      dataColumns={columns}
      handleRowAdd={handleRowAdd}
      handleRowDelete={handleRowDelete}
      handleRowUpdate={handleRowUpdate}
      rowAction={rowAction} />
  );
}

export default IncomeListTable;