import axios from "utils/axios";

import { setAlert } from "./alertAction";
import {
  CLEAR_ERRORS,
  GET_ERRORS,
  SET_ORDER,
  SET_ORDERS,
  SET_ORDER_LOOKUP,
  SET_ORDER_LOOKUP_CLIENT
} from "./types";

export const getOrders = () => async (dispatch) => {
    try {
      const res = await axios.get(`/api/order`);
      dispatch(setOrders(res.data.orders));
      dispatch(setLookUp(res.data.lookUpUsers));
      dispatch(setLookUpClient(res.data.lookUpClients));
      return true;
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      return false;
    }
  };


  export const getOrder = (id) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/order/${id}`);
      dispatch(setOrder(res.data.order));
      dispatch(setLookUp(res.data.lookUpUsers));
      dispatch(setLookUpClient(res.data.lookUpClients));
      return true;
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      return false;
    }
  };

  export const setOrder = (obj) => {
    return {
      type: SET_ORDER,
      payload: obj,
    };
  };

  export const setOrders = (obj) => {
    return {
      type: SET_ORDERS,
      payload: obj,
    };
  };

  export const clearOrder = () => (dispatch) => dispatch(setOrder(null));

  export const clearOrders = () => (dispatch) => dispatch(setOrders(null));

  export const updateOrder = (resolve,id, newData) => async (dispatch) => {
    try {
      const res = await axios.put(`/api/order/update/${id}`, newData);
      dispatch(getOrders());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const createOrder = (resolve,  serviceType,
    service,
    client,
    status,
    orderNumber,
    description,
    days,
    start,
    end,
    paymentMethod,
    cost) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/order/create`, { serviceType,
        service,
        client,
        status,
        orderNumber,
        description,
        days,
        start,
        end,
        paymentMethod,
        cost});
      dispatch(getOrders());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const deleteOrder = (resolve,id) => async (dispatch) => {
    try {
      const res = await axios.delete(`/api/order/${id}`);
      dispatch(getOrders());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };


  export const setLookUp = obj => {
    return {
      type: SET_ORDER_LOOKUP,
      payload: obj
    }
  };
  
  export const setLookUpClient = obj => {
    return {
      type: SET_ORDER_LOOKUP_CLIENT,
      payload: obj
    }
  };