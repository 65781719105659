import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from "react-redux";
import { store, persistor, syncWithServer } from "redux/store";

import UserLayout from "components/layouts/UserLayout";
import AuthLayout from "components/layouts/AuthLayout";
import Alerts from "components/partials/Alerts";
import AnimateGate from "components/ui/AnimatedGate";
import DecisionRoute from "utils/decisionRoute";

import "./App.css";


const theme = createTheme({
  palette: {
    primary: {
      light: '#598DC5',
      main: '#003366',
      dark: '#336699',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F3e500',
    }
  },
});

const App = () => {

  const [lift, setLift] = React.useState(false);

  const beforeLift = () => {
    syncWithServer(store);
    setLift(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
      <AnimateGate bootstraped={lift}/>
        <PersistGate persistor={persistor} onBeforeLift={() => beforeLift()}>
          <Router>
              <Alerts />
              <div className="App">
                {/*Global Layout here*/}
                <DecisionRoute path="/" exact={true} trueComponent={() => <Redirect to="/dashboard" />} falseComponent={() => <Redirect to="/login"/>} exp={store.getState().auth.isAuthenticated}/>
                <Route path="/login" component={AuthLayout} />
                <Route path="/forgot" component={AuthLayout} />
                <Route path="/reset" component={AuthLayout} />
                <Route path="/clients" component={UserLayout} />
                <Route path="/accounts" component={UserLayout} />
                <Route path="/finance" component={UserLayout} />
                <Route path="/dashboard" component={UserLayout} />
                <Route path="/leads" component={UserLayout} />
                <Route path="/orders" component={UserLayout} />
                <Route path="/reports" component={UserLayout} />
              </div>
            </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
