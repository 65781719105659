import {
    SET_ACCOUNTS, SET_EXPENSE_TYPES,SET_EXPENSE,
    SET_INCOME,  SET_TOTAL_EXPENSE,
    SET_TOTAL_INCOME
  } from "redux/actions/types";
  
  const initialState = {
    accounts: null,
    expenseTypes: null,
    expense: null,
    income: null,
    totalExpense: 0,
    totalIncome: 0,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case SET_ACCOUNTS:
        return {
          ...state,
          accounts: action.payload,
        };
        case SET_EXPENSE_TYPES:
          return {
            ...state,
            expenseTypes: action.payload,
          };
          case SET_EXPENSE:
          return {
            ...state,
            expense: action.payload,
          };
          case SET_INCOME:
          return {
            ...state,
            income: action.payload,
          };
          case SET_TOTAL_EXPENSE:
          return {
            ...state,
            totalExpense: action.payload,
          };
          case SET_TOTAL_INCOME:
          return {
            ...state,
            totalIncome: action.payload,
          };
      default:
        return state;
    }
  }