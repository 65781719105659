
import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  pos: {
    marginBottom: 12,
  },
  typography: {
    fontSize: "1.7rem",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  success: {
    color: "rgb(74,217,145)",
  },
  error: {
    color: "rgb(255,101,101)",
  },
}));

const LeadCard = ({ title, data, lookUpUsers, color, isNotCurrency }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          {title}
        </Typography>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          {
            data && data.map((lead, i) => {
              return (
                <Grid item xs={12} key={i}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6" component="h2">
                        {lead.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
                        <Grid item xs={12}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {lookUpUsers ? lookUpUsers[lead.owner] : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.pos} color="textSecondary">
                            Ultima modifiación: <br /> {lead.modifiedAt && formatRelative(new Date(lead.modifiedAt), new Date(), { locale: es })}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" component="p">
                            {lead.location}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                </Grid>
              );
            })
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LeadCard;
