import axios from "utils/axios";

import { setAlert } from "./alertAction";
import {
  CLEAR_ERRORS,
  GET_ERRORS,
  SET_CLIENTS,
  SET_CLIENT
} from "./types";
import { setOrders } from "redux/actions/workOrderActions";
import { getLeads } from "./leadActions";

export const getClients = () => async (dispatch) => {
    try {
      const res = await axios.get(`/api/client`);
      dispatch(setClients(res.data.clients));
      return true;
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      return false;
    }
  };

  export const setClients = (obj) => {
    return {
      type: SET_CLIENTS,
      payload: obj,
    };
  };

  export const clearClients = () => (dispatch) => dispatch(setClients(null));
  export const clearClient = () => (dispatch) => dispatch(setClient(null));

  export const updateClient = (resolve,id, type, name, shortName, description, comments, contact, activities) => async (dispatch) => {
    try {
      const res = await axios.put(`/api/client/update/${id}`, {type, name, shortName, description, comments, contact, activities});
      dispatch(getClients());
      dispatch(getClient(resolve, id));
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const createClient = (resolve,user, type, name, shortName, description, comments) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/client/create`, {type, name, shortName, description, comments});
      dispatch(getClients());
      dispatch(getLeads(user.role, user._id));
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const deleteClient = (resolve,id) => async (dispatch) => {
    try {
      const res = await axios.delete(`/api/client/${id}`);
      dispatch(getClients());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const getClient = (resolve,id) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/client/${id}`);
      dispatch(setClient(res.data.client));
      dispatch(setOrders(res.data.workOrders));
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const createAddress = (resolve, newData, client) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/client/address/create`, {...newData, client});
      dispatch(setClient(res.data.client));
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const deleteAddress = (resolve,id) => async (dispatch) => {
    try {
      const res = await axios.delete(`/api/client/address/${id}`);
      dispatch(getClients());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const updateAddress = (resolve,id, newData) => async (dispatch) => {
    try {
      const res = await axios.delete(`/api/client/address/${id}`, {...newData});
      dispatch(getClients());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const setClient = (obj) => {
    return {
      type: SET_CLIENT,
      payload: obj,
    };
  };