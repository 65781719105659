import axios from "utils/axios";

import { setAlert } from "./alertAction";
import { CLEAR_ERRORS, GET_ERRORS, SET_LEAD, SET_LOOKUP, SET_LEADSTATUS, SET_LOOKUP_CLIENT } from "./types";

export const getLeads = (role, user) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/lead/${role}/${user}`);
    dispatch(setLead(res.data.data.leads));
    dispatch(setLeadStatus(res.data.data.leadStatus));
    dispatch(setLookUp(res.data.data.lookUpUsers));
    dispatch(setLookUpClient(res.data.data.lookUpClients));
    return true;
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.message, 'error'));
    dispatch({ type: CLEAR_ERRORS });
    dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    return false;
  }
};
export const clearLeads = () => dispatch => {
  dispatch(setLead(null));
  dispatch(setLeadStatus(null));
  dispatch(setLookUp(null));
  dispatch(setLookUpClient(null));
}

export const updateLead = (resolve, id,newData) => dispatch => {
  axios
    .put(`/api/lead/${id}`, newData)
    .then(res => {
      dispatch(setLead(null));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    })
};

export const deleteLead = (resolve, id) => dispatch => {
  axios
    .delete(`/api/lead/${id}`)
    .then(res => {
      dispatch(setLead(null));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    })
};

export const createLead = (resolve, newData) => dispatch => {
  axios
    .post('/api/lead/', newData)
    .then(res => {
      dispatch(setLead(null));
      resolve();
    })
    .catch(err => {
      dispatch(setAlert(err?.response?.data?.message, 'error'));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    })
};

export const setLead = obj => {
  return {
    type: SET_LEAD,
    payload: obj
  }
};

export const setLeadStatus = obj => {
  return {
    type: SET_LEADSTATUS,
    payload: obj
  }
};

export const setLookUp = obj => {
  return {
    type: SET_LOOKUP,
    payload: obj
  }
};

export const setLookUpClient = obj => {
  return {
    type: SET_LOOKUP_CLIENT,
    payload: obj
  }
};