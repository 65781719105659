import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { registerUser } from "redux/actions/authActions.js";
import { setAlert } from "redux/actions/alertAction";

import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  fileButton: {
    fontSize: "0.8rem",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(0, 1, 0),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
}));

const RegisterUserForm = (props) => {
  const classes = useStyles();

  const authState = useSelector((state) => state.auth);
  const formLoading = authState?.formLoading;

  const [clearForm, setClearform] = useState(false);

  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    role: ""
  });

  const { name, email, role } = newUser;
  const onChange = (e) =>
    setNewUser({ ...newUser, [e.target.name]: e.target.value });

  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      email === "" 
    ) {
      dispatch(setAlert("Favor de ingresar todos los campos", "error"));
    }
    else {
      setClearform(true);
      dispatch(registerUser(newUser))
    }
  };

  useEffect(() => {
    if (clearForm && !formLoading) {
      setNewUser({
        name: "",
        email: "",
        role: "",
        organization: "",
        incentive: 0
      });
      setClearform(false);
    }
  }, [formLoading,clearForm]);

  

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Nuevo Usuario
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Nombre"
            name="name"
            helperText="Nombre del Usuario"
            autoComplete="off"
            autoFocus
            value={name}
            onChange={onChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo"
            name="email"
            helperText="Correo del Usuario"
            autoComplete="off"
            value={email}
            onChange={onChange}
          />
          {props.isAdmin && (
            <FormControl
              required
              variant="outlined"
              margin="normal"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel>Tipo de Usuario</InputLabel>
              <Select
                id="role"
                value={role}
                name="role"
                onChange={onChange}
                label="Tipo de Usuario"
              >
                <MenuItem value={"user"}>Usuario</MenuItem>
                <MenuItem value={"admin"}>Adminstrador</MenuItem>
              </Select>
              <FormHelperText>Tipo de Usuario</FormHelperText>
            </FormControl>
          )}
          {!formLoading &&
            <Button
              type="submit"
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.submit}
              value="upload"
            >
              Registrar
          </Button>
          }
          {formLoading && <CircularProgress />}
        </form>
      </CardContent>
    </Card>
  );
};

export default RegisterUserForm;
