import axios from "utils/axios";

import { setAlert } from "./alertAction";
import {
  CLEAR_ERRORS,
  GET_ERRORS,
  SET_ACCOUNTS,
  SET_EXPENSE_TYPES,
  SET_EXPENSE,
  SET_INCOME,
  SET_TOTAL_EXPENSE,
  SET_TOTAL_INCOME
} from "./types";

export const getAccounts = () => async (dispatch) => {
    try {
      const res = await axios.get(`/api/account`);
      dispatch(setAccounts(res.data.accounts));
      return true;
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      return false;
    }
  };

  export const setAccounts = (obj) => {
    return {
      type: SET_ACCOUNTS,
      payload: obj,
    };
  };

  export const clearAccounts = () => (dispatch) => dispatch(setAccounts(null));

  export const updateAccount = (resolve,id, name, bank, owner, balance) => async (dispatch) => {
    try {
      const res = await axios.put(`/api/account/update/${id}`, {name, bank, owner, balance});
      dispatch(getAccounts());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const createAccount = (resolve, name, bank, owner, balance) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/account/create`, {name, bank, owner, balance});
      dispatch(getAccounts());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const deleteAccount = (resolve,id) => async (dispatch) => {
    try {
      const res = await axios.delete(`/api/account/${id}`);
      dispatch(getAccounts());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };


  export const getExpenseTypes = () => async (dispatch) => {
    try {
      const res = await axios.get(`/api/account/expense/type`);
      dispatch(setExpenseTypes(res.data.expenseTypes));
      return true;
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      return false;
    }
  };

  export const setExpenseTypes = (obj) => {
    return {
      type: SET_EXPENSE_TYPES,
      payload: obj,
    };
  };

  export const clearExpenseTypes = () => (dispatch) => dispatch(setExpenseTypes(null));

  export const updateExpenseType = (resolve,id, name, expenseType) => async (dispatch) => {
    try {
      const res = await axios.put(`/api/account/expense/type/update/${id}`, {name,expenseType});
      dispatch(getExpenseTypes());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const createExpenseType = (resolve, name, expenseType) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/account/expense/type/create`, {name, expenseType});
      dispatch(getExpenseTypes());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const deleteExpenseType = (resolve,id) => async (dispatch) => {
    try {
      const res = await axios.delete(`/api/account/expense/type/${id}`);
      dispatch(getExpenseTypes());
      resolve();
    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const getMoneyMovements = ({start,end,account,expenseType}) => async (dispatch) => {
    try {
      let params = [{label: 'start', value: start},{label: 'end', value: end},{label: 'account', value: account},{label: 'expenseType', value: expenseType}];
      params = params.filter(param => param.value != null);
      let query = params.length >0 ? '?': '';
      params.forEach((param, i) => {
        if (i!= 0) {
          query += '&'
        }
        query +=`${param.label}=${param.value}`
      })
      const res = await axios.get(`/api/account/movement/${query}`);
      dispatch(setExpense(res.data.expense));
      dispatch(setIncome(res.data.income));
      dispatch(setTotalIncome(res.data.totalIncome));
      dispatch(setTotalExpense(res.data.totalExpense));

    } catch (err) {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    }
  };

  export const deleteMoneyMovement = (resolve,id) => async (dispatch) => {
    try {
      const res = await axios.delete(`/api/account/movement/${id}`);
      dispatch(getMoneyMovements({}));
      resolve();
    } catch (err) {
      console.log(err);
      dispatch(getMoneyMovements({}));
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const updateMoneyMovement = (resolve,id, movement) => async (dispatch) => {
    try {
      const res = await axios.put(`/api/account/movement/update/${id}`, movement);
      dispatch(getMoneyMovements({}));
      resolve();
    } catch (err) {
      console.log(err);
      dispatch(getMoneyMovements({}));
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    }
  };

  export const setExpense = (obj) => {
    return {
      type: SET_EXPENSE,
      payload: obj,
    };
  };

  export const setIncome = (obj) => {
    return {
      type: SET_INCOME,
      payload: obj,
    };
  };

  export const setTotalExpense = (obj) => {
    return {
      type: SET_TOTAL_EXPENSE,
      payload: obj,
    };
  };

  export const setTotalIncome = (obj) => {
    return {
      type: SET_TOTAL_INCOME,
      payload: obj,
    };
  };

  export const registerMoneyMovement = (resolve,movement) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/account/movement/create`, movement);
      dispatch(getMoneyMovements({}));
      resolve()
    } catch (err) {
      console.log(err);
      dispatch(getMoneyMovements({}));
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve()
    }
  };