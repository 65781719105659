import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  updateClient } from "redux/actions/clientActions";
import WorkOrderActivityTable from "components/partials/user/WorkOrderActivityTable";

const ClientActivityTable = ({ history, client }) => {
  
  const dispatch = useDispatch();

  const [activities, setActivities] = useState(client.activities ?? []);

  const removeActivity = (phase, activity) => {
    const newActivities = [... activities];
    newActivities.splice(activity, 1);
    setActivities(newActivities);
      dispatch(updateClient(() => {},client._id,  client.clientType, client.name, client.shortName, client.description, client.comments, client.contact, newActivities));
  };

  const changeActivity = (phase, activity, newData) => {
    const newActivities = [... activities];
    newActivities[activity] = newData;
    setActivities(newActivities);
    dispatch(updateClient(() => {},client._id,  client.clientType, client.name, client.shortName, client.description, client.comments, client.contact, newActivities));

  };

  const addActivity = (phase, newData) => {
    const newActivities = [... activities];
    newActivities.push({ ...newData, id: activities.length });
    setActivities(newActivities);
    dispatch(updateClient(() => {},client._id,  client.clientType, client.name, client.shortName, client.description, client.comments, client.contact, newActivities));

  };

  return (
    <WorkOrderActivityTable phase={0} addPhaseActivity={addActivity} changePhaseActivity={changeActivity} removePhaseActivity={removeActivity} activities={activities} />
  );
};

export default ClientActivityTable;
