import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { getMoneyMovements } from "redux/actions/accountActions.js";
import es from "date-fns/locale/es";

import format from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Button,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  fileButton: {
    fontSize: "0.8rem",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    margin: theme.spacing(0, 1, 0),
  },
  submit: {
    margin: theme.spacing(3, 1, 2),
  },
}));

const ExpenseQueryForm = (props) => {
  const classes = useStyles();
  const accountState = useSelector((state) => state.account);
  const accounts = accountState.accounts;
  const expenseTypes = accountState.expenseTypes;

  const accountLookUp = accounts?.map(account =>( <MenuItem value={account._id}  key={account._id}>{account.name}</MenuItem>))
  const expenseTypeLookUp = expenseTypes?.map(expenseType =>( <MenuItem value={expenseType.name} key={expenseType.name}>{expenseType.name}</MenuItem>))


  const [query, setQuery] = useState({
  });

  const { start, end,  account} = query;
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [expenseType, setExpenseType] = useState('');

  const onChange = (e) =>
    setQuery({ ...query, [e.target.name]: e.target.value });

  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    const queryToSend ={...query} ;
    if (query.start) {
        queryToSend.start = query.start.getTime()
    }
    if (query.end) {
        queryToSend.end = query.end.getTime()
    }
    await dispatch(getMoneyMovements(queryToSend))
    setFormLoading(false);
  };

  
  const changeDate1 = (date) => {
    setQuery({ ...query, start: date});
    setOpen1(false);
  };

  const changeDate2 = (date) => {
    setQuery({ ...query, end: date});
    setOpen2(false);
  };
  const date = new Date();
  return (
    <Card className={classes.root}>
      <CardContent>
        <form className={classes.form} onSubmit={onSubmit}>

            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel>Cuenta Contable</InputLabel>
              <Select
                id="expenseType"
                value={expenseType}
                name="expenseType"
                onChange={(e) => setExpenseType(e.target.value)}
                label="Cuenta Contable"
              >
                {expenseTypeLookUp}
              </Select>
              <FormHelperText>Cuenta Contable</FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              margin="normal"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel>Cuenta Bancaria</InputLabel>
              <Select
                id="account"
                value={account}
                name="account"
                onChange={onChange}
                label="Cuenta Bancaria"
              >
                {accountLookUp}
              </Select>
              <FormHelperText>Cuenta Bancaria</FormHelperText>
            </FormControl>
        
              <TextField
              variant="outlined"
              margin="normal"
              label="Inicio"
              fullWidth
                value={start ? format(start, "eee, dd MMM yyyy", {
                    locale: es,
                  }) :""}
                onChange={(e) => e.preventDefault()}
                onClick={() => setOpen1(!open1)}
              />
            <div
              style={{
                position: "absolute",
                visibility: !open1 ? "hidden" : "visible",
                zIndex: "9999999999"
              }}
            >
              <Calendar onChange={changeDate1} value={start ?? date} locale={"es"} />
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              label="Fin"
              fullWidth
                value={end ? format(end, "eee, dd MMM yyyy", {
                    locale: es,
                  }): ""}
                onChange={(e) => e.preventDefault()}
                onClick={() => setOpen2(!open2)}
              />
            <div
              style={{
                position: "absolute",
                visibility: !open2 ? "hidden" : "visible",
                zIndex: "9999999999"
              }}
            >
              <Calendar onChange={changeDate2} value={end  ?? date} locale={"es"} />
            </div>
          {!formLoading &&
            <Button
              type="submit"
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.submit}
              value="upload"
            >
              Filtrar
          </Button>
          }
          {formLoading && <CircularProgress />}
        </form>
      </CardContent>
    </Card>
  );
};

export default ExpenseQueryForm;
