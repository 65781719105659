import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateClient } from "redux/actions/clientActions";

import RestfulTable from "./GenericRestfulTable";

const ClientContactListTable = ({ client }) => {
    const dispatch = useDispatch();

    const rowAction = [];
   const contactType ={ WhatsApp:"WhatsApp", Email:"Email", Sitio: "Sitio", Celular:"Celular", Trabajo: "Trabajo", Personal: "Personal", Skype: "Skype", Twitter: "Twitter", Facebook: "Facebook", Fax: "Fax"}
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(client.contact);
    const columns = [
        {
            title: '_id',
            field: '_id',
            hidden: true
        },
        {
            title: 'Nombre',
            field: 'contactName'
        },
        {
            title: 'Puesto',
            field: 'contactPosition'
        },
        {
            title: 'Tipo',
            field: 'contactType',
            lookup: contactType,
            initialEditValue: "TRABAJO"
        },
        {
            title: 'Contacto',
            field: 'contactValue'
        }
    ];

    const handleRowUpdate = (newData, oldData, resolve) => {
        const changedD =[...data];
        changedD[oldData.tableData._id] = newData;
        setData(changedD);
        dispatch(updateClient(resolve,client._id,  client.clientType, client.name, client.shortName, client.description, client.comments, changedD, client.activities));
    }

    const handleRowAdd = (newData, resolve) => {
        const changedD =[...data];
        changedD.push({...newData});
        setData(changedD);
        dispatch(updateClient(resolve,client._id,  client.clientType, client.name, client.shortName, client.description, client.comments, changedD, client.activities));
    }

    const handleRowDelete = (oldData, resolve) => {
        console.log(oldData)
        const changedD =[...data];
        changedD.splice(oldData.tableData._id, 1);
        setData(changedD);
        dispatch(updateClient(resolve,client._id,  client.clientType, client.name, client.shortName, client.description, client.comments, changedD, client.activities));
    }
    const fetchData = async () => {
        setIsLoading(true);
        setIsLoading(false); 
      }

    return (
        <RestfulTable
             isLoading={isLoading}
            onDataFetch={fetchData}
            title={"Contactos"}
            data={data}
            dataColumns={columns}
            handleRowUpdate={handleRowUpdate}
            handleRowDelete={handleRowDelete}
            handleRowAdd={handleRowAdd}
            rowAction={rowAction} />
    );
}

export default ClientContactListTable;