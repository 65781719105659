import React, { useEffect } from "react";

import { Grid, Paper } from "@material-ui/core/";
import WorkOrderForm from "components/partials/user/WorkOrderForm";
import WorkOrderListTable from "components/partials/user/WorkOrderListTable";
import { useSelector, useDispatch } from "react-redux";
import { getOrder, clearOrders, clearOrder } from "redux/actions/workOrderActions";

const OrderPage = ({ history, match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getOrder(id));
    }
    return () => {
      dispatch(clearOrders());
      dispatch(clearOrder());
    }
  }, [dispatch, id]);
  const orderState = useSelector((state) => state.order);
  const { order } = orderState;

  const serviceType = {
    CURSO: 'CURSO',
    CONSULTORIA: 'CONSULTORIA',
    AUDITORIA: 'AUDITORIA',
  }

  const workOrderStatus = {
    EN_ESPERA: 'EN_ESPERA',
    EN_PROGRESO: 'EN_PROGRESO',
    CONCLUIDO: 'CONCLUIDO',
  }

  const paymentMethods = {
    CONTADO: 'CONTADO',
    PARCIALIDADES: 'PARCIALIDADES',
  }
  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={12}>
            <Paper>
              {(id == null || order == null) ?
                <WorkOrderListTable history={history} serviceType={serviceType} workOrderStatus={workOrderStatus} paymentMethods={paymentMethods} /> : <WorkOrderForm  history={history} id={id} serviceType={serviceType} workOrderStatus={workOrderStatus}  paymentMethods={paymentMethods} />}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderPage;
