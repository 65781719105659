import axios from "utils/axios";
import setAuthToken from "utils/setAuthToken";
import { setAlert } from "./alertAction";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  CLEAR_ERRORS,
  LOGIN_SUCCESS,
  PASSWORD_RESET,
  RESET_STATE,
  REMEMBER_ME,
  PASSWORD_CHANGED,
  USER_FORM_LOADING,
} from "./types";

// Register User
export const registerUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: USER_FORM_LOADING, payload: true });
    const res = await axios.post("/api/auth/register", user);
    dispatch({ type: USER_FORM_LOADING, payload: false });
    dispatch(setAlert("Usuario creado existosamente", "success"));
    return true;
  } catch (err) {
    dispatch(setAlert("Error al registrar usuario", "error"));
    dispatch({ type: CLEAR_ERRORS });
    dispatch({ type: USER_FORM_LOADING, payload: false });
    dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    return false;
  }
};

// Login - get user token
export const loginUser = (email, password, rememberMe) => async (dispatch) => {
  try {
    const res = await axios.post("/api/auth/login", { email, password });
    // Save to localStorage

    // Set token to localStorage
    const { token } = res.data;
    //localStorage.setItem("jwtToken", token);
    // Set token to Auth header
    setAuthToken(token);
    // Set current token
    let type = LOGIN_SUCCESS;
    if (res.status === 202) {
      type = PASSWORD_RESET;
    }
    dispatch({ type: REMEMBER_ME, payload: rememberMe });
    dispatch({ type, payload: token });
    dispatch(setUserLoading());
    return true;
  } catch (err) {

    dispatch(setAlert(err?.response?.data?.message, "error"));
    dispatch({ type: CLEAR_ERRORS });
    dispatch(setCurrentUser({}));
    dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    return false;
  }
};

// User loading
export const setUserLoading = () => async (dispatch) => {
  try {
    dispatch({ type: USER_LOADING });
    const res = await axios.get("/api/auth/me");
    const userData = res.data;
    dispatch(setCurrentUser(userData));
    return true;
  } catch (err) {
    dispatch(setCurrentUser({}));
    return false;
  }
};

// Recovers password
export const recoverPassword = (email, history) => async (dispatch) => {
  try {
    const res = await axios.post("/api/auth/forget", { email });
    dispatch(setAlert("Contraseña enviada", "success"));
    dispatch(setCurrentUser({}));
    history.push("/login");
    return true;
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "error"));
    dispatch({ type: CLEAR_ERRORS });
    dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    return false;
  }
};

// Reset password
export const resetPassword = (user, password, newPassword, history) => async (
  dispatch
) => {
  try {
    const res = await axios.post("/api/auth/reset", {
      id: user._id,
      password,
      newPassword,
    });
    dispatch({ type: PASSWORD_CHANGED });
    dispatch(setAlert("Contraseña cambiada existosamente", "success"));
    history.push("/dashboard");
    return true;
  } catch (err) {
    dispatch(setAlert(err.response.data.message, "error"));
    dispatch({ type: CLEAR_ERRORS });
    dispatch({ type: GET_ERRORS, payload: err.response.data });
    return false;
  }
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  //dispatch(setCurrentUser({}));
  dispatch({ type: RESET_STATE });
};
