import React from "react";
import { CSSTransition } from "react-transition-group";
import { makeStyles } from "@material-ui/core/styles";

import logo from "assets/logo.png";

const userLayoutStyles = makeStyles((theme) => ({
  sliderBackground: {
    backgroundColor: theme.palette.primary.main,
  },
  logo: {
    width: "35vw",
  },
}));

const AnimateGate = ({ bootstraped }) => {
  const classes = userLayoutStyles({});

  return (
    <CSSTransition in={bootstraped} timeout={2000} classNames="slider">
      <div className={`slider ${classes.sliderBackground}`}>
        <img src={logo} alt="Logo" className={classes.logo} />
      </div>
    </CSSTransition>
  );
};

export default AnimateGate;
