import React from "react"
import {Route, Redirect} from "react-router-dom"
import { useSelector } from "react-redux"

const PrivateRoute = ({component: Component, ...rest}) => {
  
  const state = useSelector(state => state.auth);
  
  return (
    <Route {...rest} render={props => {
        if (!state.isAuthenticated) {
            return <Redirect to="/login" />
        }
        return <Component {...props} />
    }} />
  )
}

export default PrivateRoute