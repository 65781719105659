import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getClients, updateClient, deleteClient, clearClients, createClient } from "redux/actions/clientActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";

import RestfulTable from "./GenericRestfulTable";

const ClientListTable = ({history, clientDefaultType}) => {

  const dispatch = useDispatch();

  const clientState = useSelector((state) => state.client);
  const clients = clientState.clients;
  const authState = useSelector((state) => state.auth);
  const user = authState.user.data;
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const rowAction = [
    {
      icon: 'visibility',
      tooltip: 'Ver usuario',
      onClick: (event, rowData) => history.push(`/clients/${rowData._id}`)
    }
  ];
  const clientType = {
    CLIENTE: 'Cliente',
    PROSPECTO: 'Prospecto',
    PROVEEDOR: 'Proveedor',
    COMPETIDOR: 'Competidor',
    ASOCIADO: 'Asociado',
    ALIANZA: 'Alianza Estratégica',
    ENTIDAD: 'Entidad Reguladora',
  }

  const columns = [
    {
      title: '_id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Nombre',
      field: 'name'
    },
    {
      title: 'Nombre Corto',
      field: 'shortName'
    },
    {
      title: 'Tipo',
      field: 'clientType',
      lookup: clientType,
      initialEditValue: clientDefaultType
    },
    {
      title: 'Descripción',
      field: 'description',
    },
    {
      title: 'Comentarios',
      field: 'comments',
    }
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(updateClient(resolve, oldData._id,  newData.clientType, newData.name, newData.shortName, newData.description, newData.comments));
    }
  }

  const handleRowAdd = (newData, resolve) => {
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(createClient(resolve,user, newData.clientType, newData.name, newData.shortName, newData.description, newData.comments));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    dispatch(deleteClient(resolve, oldData._id));
  }

  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(getClients());
    setIsLoading(false); 
  }
  
  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      fetchData();
    }
    return () => {
      dispatch(clearClients());
    }
  }, [dispatch, isFirstLoad]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RestfulTable
      isLoading={isLoading}
      onDataFetch={fetchData}
      title={"Lista de Clientes"}
      data={clients?clients:[]}
      dataColumns={columns}
      handleRowUpdate={handleRowUpdate}
      handleRowDelete={handleRowDelete}
      handleRowAdd={handleRowAdd}
      rowAction={rowAction} />
  );
}

export default ClientListTable;