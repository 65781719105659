import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import alertReducer from "./alertReducer";
import uiReducer from "./uiReducer";
import userReducer from "./userReducer";
import leadReducer from "./leadReducer";
import clientReducer from "./clientReducer";
import accountReducer from "./accountReducer";
import orderReducer from "./workOrderReducer";

import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  ui: uiReducer,
  alert: alertReducer,
  user: userReducer,
  lead: leadReducer,
  client: clientReducer,
  account: accountReducer,
  order: orderReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    storage.removeItem('persist:root')
    state = undefined
  }
  return appReducer(state, action)
};

export default rootReducer;