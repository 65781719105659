import React from "react";

import { Grid, Paper } from "@material-ui/core/";
import ClientTable from "components/partials/user/ClientListTable";

const UserPage = ({ history }) => {
  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={12}>
            <Paper>
              <ClientTable history={history} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserPage;
