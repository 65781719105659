import axios from "utils/axios";

import { setAlert } from "./alertAction";
import {
  CLEAR_ERRORS,
  GET_ERRORS,
  SET_USERS,
} from "./types";

export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/user");
    dispatch(setUsers(res.data.users));
    return true;
  } catch (err) {
    dispatch(setAlert(err?.response?.data?.message, "error"));
    dispatch({ type: CLEAR_ERRORS });
    dispatch({ type: GET_ERRORS, payload: err?.response?.data });
    return false;
  }
};

export const clearUsers = () => (dispatch) =>
  dispatch(setUsers(null));

export const setUsers = (obj) => {
  return {
    type: SET_USERS,
    payload: obj,
  };
};

export const updateUser = (resolve, id, name, email) => (
  dispatch
) => {
  axios
    .put(`api/user/updateUser/${id}`, { id, name, email })
    .then((res) => {
      dispatch(getUsers());
      resolve();
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    });
};

export const deleteUser = (resolve, id) => (dispatch) => {
  axios
    .delete(`api/deleteUser/${id}`)
    .then((res) => {
      //Even though we set the distributors/sellers as gargabe data, this forces the table to re-render and fetch the new data
      dispatch(getUsers());
      resolve();
    })
    .catch((err) => {
      dispatch(setAlert(err?.response?.data?.message, "error"));
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: GET_ERRORS, payload: err?.response?.data });
      resolve();
    });
};

