import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getAccounts, updateAccount, deleteAccount, clearAccounts, createAccount } from "redux/actions/accountActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";

import RestfulTable from "./GenericRestfulTable";

const AccountListTable = ({history}) => {

  const dispatch = useDispatch();

  const accountState = useSelector((state) => state.account);
  const accounts = accountState.accounts;

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const rowAction = [
  
  ];

  const columns = [
    {
      title: '_id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Nombre',
      field: 'name'
    },
    {
      title: 'Banco',
      field: 'bank'
    },
    {
      title: 'Propietario',
      field: 'owner',
    },
    {
      title: 'Balance',
      field: 'balance',
      type: 'currency'
    }
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {

    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(updateAccount(resolve, oldData._id,  newData.name, newData.bank, newData.owner, newData.balance));
    }
  }

  const handleRowAdd = (newData, resolve) => {
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(createAccount(resolve,newData.name, newData.bank, newData.owner, newData.balance));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    dispatch(deleteAccount(resolve, oldData._id));
  }

  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(getAccounts());
    setIsLoading(false); 
  }
  
  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      fetchData();
    }
    return () => {
      dispatch(clearAccounts());
    }
  }, [dispatch, isFirstLoad]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RestfulTable
      isLoading={isLoading}
      onDataFetch={fetchData}
      title={"Lista de Cuentas Bancarias"}
      data={accounts?accounts:[]}
      dataColumns={columns}
      handleRowUpdate={handleRowUpdate}
      handleRowDelete={handleRowDelete}
      handleRowAdd={handleRowAdd}
      rowAction={rowAction} />
  );
}

export default AccountListTable;