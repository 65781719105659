import { SET_LEAD, SET_LEADSTATUS, SET_LOOKUP, SET_LOOKUP_CLIENT } from "redux/actions/types";

const initialState = {
  leads: null,
  lookupUsers: null,
  leadstatus: null,
  lookUpClients: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LEAD:
      return {
        ...state,
        leads: action.payload
      }
    case SET_LOOKUP:
      return {
        ...state,
        lookupUsers: action.payload
      }
    case SET_LEADSTATUS:
      return {
        ...state,
        leadstatus: action.payload
      }
    case SET_LOOKUP_CLIENT:
      return {
        ...state,
        lookUpClients: action.payload
      }
    default:
      return state;
  }
}