import React, {useState} from "react";
import { useSelector } from "react-redux";

import RestfulTable from "./GenericRestfulTable";

const WorkOrderActivityTable = ({ phase, addPhaseActivity, changePhaseActivity, removePhaseActivity, activities }) => {

    const authState = useSelector((state) => state.auth);
    const workOrderState = useSelector((state) => state.order);
    const lookUpUsers = workOrderState.lookUpUsers ?? [];
    const rowAction = [];
    const priorities = { BAJA: "BAJA", MEDIA: "MEDIA", ALTA: "ALTA" };
    const activityTypes = { GENERAL: "GENERAL", CITA: "CITA", LLAMADA: "LLAMADA", VISITA: "VISITA", ZOOM:"ZOOM" };
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(activities);
    const columns = [
        {
            title: '_id',
            field: '_id',
            hidden: true
        },
        {
            title: "Responsable",
            field: "owner",
            lookup: lookUpUsers,
            initialEditValue: authState.user.data._id
        },
        {
            title: 'Actividad',
            field: 'activity'
        },
        {
            title: 'Descripción',
            field: 'description'
        },
        {
            title: 'Prioridad',
            field: 'priority',
            lookup: priorities,
            initialEditValue: "BAJA"
        },
        {
            title: 'Tipo',
            field: 'activityType',
            lookup: activityTypes,
            initialEditValue: "GENERAL"
        },
        {
            title: 'Duración',
            field: 'days',
            type: 'numeric',
            default: 1
        },
        {
            title: 'Expiración',
            field: 'activityExpiration',
            type: 'date'
        },
    ];

    const handleRowUpdate = (newData, oldData, resolve) => {
        changePhaseActivity(phase, oldData.tableData._id, newData);
        const changedD =[...data];
        changedD[oldData.tableData._id] = newData;
        setData(changedD);
        resolve();
    }

    const handleRowAdd = (newData, resolve) => {
        addPhaseActivity(phase, newData);
        const changedD =[...data];
        changedD.push(newData);
        setData(changedD);
        resolve();
    }

    const handleRowDelete = (oldData, resolve) => {
        removePhaseActivity(phase, oldData.tableData._id);
        const changedD =[...data];
        changedD.splice(oldData.tableData._id, 1);
        setData(changedD);
        resolve();
    }
    const fetchData = async () => {
        setIsLoading(true);
        setIsLoading(false); 
      }

    return (
        <RestfulTable
             isLoading={isLoading}
            onDataFetch={fetchData}
            title={"Actividades"}
            data={data}
            dataColumns={columns}
            handleRowUpdate={handleRowUpdate}
            handleRowDelete={handleRowDelete}
            handleRowAdd={handleRowAdd}
            rowAction={rowAction} />
    );
}

export default WorkOrderActivityTable;