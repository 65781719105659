import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useLocation } from "react-router-dom";

import Login from "components/auth/Login";
import ForgotPassword from "components/auth/ForgotPassword";
import ResetPassword from "components/auth/ResetPassword";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, CssBaseline } from "@material-ui/core";

//Login & Forgot layout
const authStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  sidebar: {
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    margin: theme.spacing(8, 0),
    padding: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const AuthLayout = ({ history }) => {
  //Get auth state
  const authState = useSelector((state) => state.auth);

  const classes = authStyles({});
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.isAuthenticated && location.pathname !== "/reset") {
      history.push("/dashboard");
    }
  }, [authState.isAuthenticated, history, dispatch, location.pathname]);

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={5} className={classes.sidebar} />
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            {!authState.isAuthenticated ? (
              <Switch location={location}>
                <Route path="/forgot" component={ForgotPassword} />
                <Route path="*" component={Login} />
              </Switch>
            ) : (
              <Switch location={location}>
                <Route path="/reset" component={ResetPassword} />
              </Switch>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuthLayout;
