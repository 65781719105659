import React, { useEffect, useState } from "react";

import { Grid, Paper } from "@material-ui/core/";
import UpdateClientForm from "components/partials/user/UpdateClientForm";
import ClientTable from "components/partials/user/ClientListTable";
import { useSelector, useDispatch } from "react-redux";
import { getClient, clearClients, clearClient } from "redux/actions/clientActions";
import AddressTable from "components/partials/user/AdressTable";
import WorkOrderListTable from "components/partials/user/WorkOrderListTable";
import ClientContactListTable from "components/partials/user/ClientContactListTable";
import ClientActivityTable from "components/partials/user/ClientActivityTable";

const ClientPage = ({ history, match }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(getClient(() => { }, id));
    } else {
      dispatch(clearClient());
    }
    return () => {
      dispatch(clearClients());
    }
  }, [dispatch, id]);
  const clientState = useSelector((state) => state.client);
  const { client } = clientState;
  const serviceType = {
    CURSO: 'CURSO',
    CONSULTORIA: 'CONSULTORIA',
    AUDITORIA: 'AUDITORIA',
  }

  const workOrderStatus = {
    EN_ESPERA: 'EN_ESPERA',
    EN_PROGRESO: 'EN_PROGRESO',
    CONCLUIDO: 'CONCLUIDO',
  }

  const paymentMethods = {
    CONTADO: 'CONTADO',
    PARCIALIDADES: 'PARCIALIDADES',
  }

  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={12}>
            <Paper>
              {(id == null || client == null) ?
                <ClientTable history={history} clientDefaultType={"CLIENTE"} /> : <UpdateClientForm client={client} id={id} />}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {id != null && client != null ? (<>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
            <Grid item xs={12} md={12}>
              <Paper>
                <AddressTable history={history} id={id} addresses={client.addresses} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
            <Grid item xs={12} md={12}>
              <Paper>
              <ClientActivityTable  client={client} />
                
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
            <Grid item xs={12} md={12}>
              <Paper>
                <ClientContactListTable  client={client} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </>) : null}
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={12}>
            <Paper>
              <WorkOrderListTable history={history} serviceType={serviceType} workOrderStatus={workOrderStatus} clientId={id ?? null} paymentMethods={paymentMethods} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientPage;
