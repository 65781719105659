import React from "react";

import { NavLink } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const isEmpty = require("is-empty");

const useStyles = makeStyles((theme) => ({
  listItemText: {
    padding: theme.spacing(1),
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  divider: {
    padding: theme.spacing(1),
  },
  active: {
    backgroundColor: "rgba(255, 255, 255, 0.12)",
  },
}));

const DrawerList = ({ history }) => {
  const drawerList = [{label: 'Inicio', link: '/dashboard'},{label: 'Clientes', link: '/clients'}, {label: 'Contabilidad', link: '/finance'}, {label: 'Cuentas', link: '/accounts'}, {label: 'CRM', link: '/leads'}, {label: 'Ordenes de trabajo', link: '/orders'}, {label: 'Cursos y consultorías', link: '/courses'}];
  const classes = useStyles();
  return (
    <div>
      <div className={classes.toolbar} />
      <div className={classes.divider} />
      <List>
        {!isEmpty(drawerList) &&
          drawerList.map((item) => (
            <ListItem
              button
              key={item.link}
              component={NavLink}
              exact
              to={item.link}
              activeClassName={classes.active}
            >
              {/* item.icon to get icon */}
              <ListItemText
                primary={item.label}
                className={classes.listItemText}
              />
            </ListItem>
          ))}

      </List>
    </div>
  );
};

export default DrawerList;
