import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { getClient, createAddress, updateAddress, deleteAddress } from "redux/actions/clientActions";

import RestfulTable from "./GenericRestfulTable";

const AddressTable = ({history, id, addresses}) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const rowAction = [
  ];
  const addressType = {
    PRINCIPAL: 'Principal',
    ALMACEN: 'Almacén',
    FACTURACION: 'Facturación',
    OTRO: 'Otro',
  }
  const columns = [
    {
      title: '_id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Calle',
      field: 'street'
    },
    {
      title: 'Exterior',
      field: 'ext'
    },
    {
        title: 'Interior',
        field: 'int'
      },
      {
        title: 'Colonia',
        field: 'neighborhood'
      },
      {
        title: 'Ciudad',
        field: 'city'
      },
      {
        title: 'Código Postal',
        field: 'zip'
      },
    {
      title: 'Tipo',
      field: 'addressType',
      lookup: addressType,
      initialEditValue: "PRINCIPAL"
    },
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {
   dispatch(updateAddress(resolve, oldData._id,  newData));
  }

  const handleRowAdd = (newData, resolve) => {
    dispatch(createAddress(resolve, newData, id));
  }

  const handleRowDelete = (oldData, resolve) => {
   dispatch(deleteAddress(resolve, oldData._id));
  }

  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(getClient(id));
    setIsLoading(false); 
  }
  
  return (
    <RestfulTable
      isLoading={isLoading}
      onDataFetch={fetchData}
      title={"Lista de Direcciones"}
      data={addresses?addresses:[]}
      dataColumns={columns}
      handleRowUpdate={handleRowUpdate}
      handleRowDelete={handleRowDelete}
      handleRowAdd={handleRowAdd}
      rowAction={rowAction} />
  );
}

export default AddressTable;