import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getExpenseTypes, updateExpenseType, deleteExpenseType, clearExpenseTypes, createExpenseType } from "redux/actions/accountActions";
import { setAlert } from "redux/actions/alertAction";
import { CLEAR_ERRORS } from "redux/actions/types";

import RestfulTable from "./GenericRestfulTable";

const ExpenseTypeListTable = ({history}) => {

  const dispatch = useDispatch();

  const accountState = useSelector((state) => state.account);
  const expenseTypes = accountState.expenseTypes;

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const rowAction = [
  
  ];

  const expenseTypeType = {
    Directo: 'Directo',
    Indirecto: 'Indirecto'
  }

  const columns = [
    {
      title: '_id',
      field: '_id',
      hidden: true
    },
    {
      title: 'Nombre',
      field: 'name'
    },
    {
      title: 'Tipo',
      field: 'expenseType',
      lookup: expenseTypeType,
      initialEditValue: "Directo"
    }
  ];

  const handleRowUpdate = (newData, oldData, resolve) => {

    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(updateExpenseType(resolve, oldData._id,  newData.name, newData.expenseType));
    }
  }

  const handleRowAdd = (newData, resolve) => {
    if(newData.name === ""){
      dispatch(setAlert("El nombre es requerido", 'error'));
      dispatch({type: CLEAR_ERRORS});
      resolve();
    }
    else{
      dispatch(createExpenseType(resolve,newData.name, newData.expenseType));
    }
  }

  const handleRowDelete = (oldData, resolve) => {
    dispatch(deleteExpenseType(resolve, oldData._id));
  }

  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(getExpenseTypes());
    setIsLoading(false); 
  }
  
  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      fetchData();
    }
    return () => {
      dispatch(clearExpenseTypes());
    }
  }, [dispatch, isFirstLoad]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RestfulTable
      isLoading={isLoading}
      onDataFetch={fetchData}
      title={"Lista de Cuentas Contables"}
      data={expenseTypes?expenseTypes:[]}
      dataColumns={columns}
      handleRowUpdate={handleRowUpdate}
      handleRowDelete={handleRowDelete}
      handleRowAdd={handleRowAdd}
      rowAction={rowAction} />
  );
}

export default ExpenseTypeListTable;