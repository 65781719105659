import axios from "axios";
import { store } from "redux/store";

const axiosInstance = axios.create({
  baseURL: "https://siaa-crasa.nullpointer.com.mx",
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  config.params = config.params || {};
  return config;
});


export default axiosInstance;
