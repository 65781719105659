import React, { useEffect } from "react";

import { Grid, Paper } from "@material-ui/core/";
import AccountTable from "components/partials/user/AccountListTable";
import ExpenseTypeListTable from "components/partials/user/ExpenseTypeListTable";
import MoneyMovementForm from "components/partials/user/MoneyMovementForm";
import { useDispatch } from "react-redux";
import { clearAccounts } from "redux/actions/accountActions";


const AccountPage = ({ history }) => {

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearAccounts());
    }
  }, [dispatch]);
  return (
    <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"stretch"} direction={"row"}>
          <Grid item xs={12} md={12}>
            <Paper>
              <MoneyMovementForm history={history} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper>
              <AccountTable history={history} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper>
              <ExpenseTypeListTable history={history} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountPage;
