import { SET_DRAWERLIST, SET_IS_OTHER_USER } from "redux/actions/types";

const initialState = {
  drawerList: [],
  isOtherUser: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DRAWERLIST:
      return {
        ...state,
        drawerList: action.payload
      };
    case SET_IS_OTHER_USER:
      return {
        ...state,
        isOtherUser: action.payload
      }
    default:
      return state;
  }
}